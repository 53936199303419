



export const Settings = () => {
    return (
        <div>
            Settings
        </div>
    )
}