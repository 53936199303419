import '../Styles/App.css';
import { Desktop } from './Desktop/Desktop';

function App() {
  return (
    <div className="App">
      <Desktop/>
    </div>
  );
}

export default App;
