

export const Resume = () => {

    return (
        <div style={{width: '100%', height: '99%'}}>
            <iframe 
            style={{width: '99%', height: "100%"}} 
            src="https://docs.google.com/document/d/1cDqtAiy8Swz8PLTgg-J2wzdU81cmJXCtFxphEMZ7IlY/edit?usp=sharing"/>
        </div>
    )
}